(function($){

  var dyw = {

    init: function(){

      
      dyw.navToggle();
      dyw.simpleDynamicLink();

      if($('form.o-wire').length){
        dyw.oWireForms();
      }
      if($('a.local-navigation').length){
        dyw.setPrgramSection();
        dyw.switchProgramSection();
      }
      if($('.stat-slider').length){
        dyw.statSlider();
      }
      if($('.testimonial-slider').length){
        dyw.testimonialSlider();
      }
      if($('.zip-widget').length){
        dyw.loadZipWidget();
      }
      if($('.lightbox').length){
        dyw.lightboxes();
      }
      if($('.expand-trigger').length){
        dyw.expander();
      }
      if($('#countdown-timer').length){
        dyw.timer();
      }
      if($('.reset-pass').length){
        dyw.resetPassword();
      }
      if($('#zip-check').length){
        dyw.validateZip();
      }
      if($('.detail-section').length){
        dyw.editMySections();
        dyw.oWireFormsDynamic();
        dyw.fiWireForm();
      }

    },
    simpleDynamicLink: function(){

      $(document).on('click', 'span.dynamic-link', function(){
        var thisaction = $(this).data('action');
        var endpoint = $(this).data('endpoint');
        $.post(endpoint+ '?thisaction='+thisaction,function(data){ });
      });

      $(document).on('click', 'a.dynamic', function(e){
        e.preventDefault();
        var endpoint = $(this).data('endpoint');
        var thisset = $(this).data('set');
        $.post(endpoint+ '?set='+thisset,function(data){ });
      });

    },
    navToggle: function(){

      $('.header-nav-toggle').on('click', function(){
        $('body').addClass('nav-open');
        setTimeout(function(){
          $('.nav-all').addClass('ready');
        },100);
      });

      $('.nav-close').on('click', function(){
        $('body').removeClass('nav-open');
        $('.nav-all').removeClass('ready');
      });

    },
    headerBackground: function(){

      if(window.scrollY === 0){
        $('body').removeClass('scrolled');
      } else {
        $('body').addClass('scrolled');
      }

    },
    timer: function(){
    
      var end_html = '<span class="days">00</span> <span class="hours">00</span> <span class="minutes">00</span> <span class="seconds">00</span>';    
    
      // Set the date we're counting down to
      // e.g. "Jan 5, 2024 15:37:25"
      var enddate = $('#countdown-timer').data('end');
      var countDownDate = new Date(enddate).getTime();

      // Update the count down every 1 second
      var x = setInterval(function() {

        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (days < 10) { days = '0' + days }
        if (hours < 10) { hours = '0' + hours }
        if (minutes < 10) { minutes = '0' + minutes }
        if (seconds < 10) { seconds = '0' + seconds }

        // Display the result in the element with id="demo"
        // document.getElementById("demo").innerHTML = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
        
        var timer_html = '<span class="days">' +days+ '</span><span class="hours">' +hours+ '</span><span class="minutes">' +minutes+ '</span><span class="seconds">' +seconds+ '</span>';
      
        $('#countdown-timer').html(timer_html);
        

        // If the count down is finished, write some text 
        if (distance < 0) {
          clearInterval(x);
          // document.getElementById("demo").innerHTML = "EXPIRED";
          $('#countdown-timer').html(end_html);
        }
      }, 1000);
          
    },
    lightboxes: function(){

      $('a.lightbox').on('click', function(e){
        e.preventDefault();
        var type = $(this).data('type');
        var id = $(this).data('id');

        var video_html = '<iframe width="560" height="315" src="https://www.youtube.com/embed/' +id+ '?&autoplay=1&rel=0&controls=0" frameborder="0" allow="accelerometer; autoplay; modestbranding; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';

        var thisentry = $(this).closest('.panel');
        $('.video-lightbox',thisentry).addClass('active');
        $('.video-lightbox-contain',thisentry).html(video_html);
      });

      $('.video-lightbox-shade').on('click', function(){
        var thisentry = $(this).closest('.panel');
        $('.video-lightbox',thisentry).removeClass('active');
        $('.video-lightbox-contain',thisentry).html('');
      });

    },
    // deprecated; move to oWireFormsDynamic
    oWireForms: function(){

      $('form.o-wire').on('submit', function(e){
        e.preventDefault();
        // vaidate and then submit if pass
        // default validate on server
        var thisform = $(this);
        
        endpoint = thisform.data('endpoint');
        if(endpoint != undefined) {
          endpoint = '/' + endpoint;
        } else {
          endpoint = '/savepublicform';
        }
        
        $.ajax({
          url: endpoint,
          type: 'POST',
          data: thisform.serialize()
        });        
      });

    },
    oWireFormsDynamic: function(){

      $(document).on('submit', 'form.o-wire', function(e){
        e.preventDefault();
        // vaidate and then submit if pass
        // default validate on server
        var thisform = $(this);
        
        endpoint = thisform.data('endpoint');
        if(endpoint != undefined) {
          endpoint = '/' + endpoint;
        } else {
          endpoint = '/savepublicform';
        }
        
        $.ajax({
          url: endpoint,
          type: 'POST',
          data: thisform.serialize()
        });        
      });

    },
    fiWireForm: function(){
      $(document).on('submit', 'form.fi-wire', function(e){
        e.preventDefault();
        var thisform = $(this);
        var file = $('input[name="person_figure"]',thisform)[0].files[0];
        var person = $('input[name="person"]',thisform).val();

        const formData = new FormData();
        formData.append('figure', file);
        formData.append('person', person);
        
        endpoint = thisform.data('endpoint');
        if(endpoint != undefined) {
          endpoint = '/' + endpoint;
        } else {
          endpoint = '/savepublicform';
        }

        $.ajax({
          url: endpoint,
          type: 'POST',
          data: formData,
          processData: false,
          contentType: false
        });

      });

      $(document).on('click', '.fi-wire-button', function(){
        var thisid = $(this).data('id');
        var endpoint = $(this).data('action');
        $.post(endpoint+ '?id='+thisid,function(data){ });
      });
    },
    loadZipWidget: function(){

      var zip_widget_html = '<div class="small-zip"><input type="text" placeholder="ZIP" class="center-text" name="zip_input"><input type="submit" class="gray" name="zip_lookup" value="See Your Program"></div>';

      $('.zip-widget').each(function(){
        $(this).html(zip_widget_html);
      });

    },
    setPrgramSection: function(){

      // get query parameter and update accordingly
      // https://stackoverflow.com/questions/4656843/get-querystring-from-url-using-jquery

      // else default is first section
      $('a.local-navigation[data-show="overview"]').addClass('active');
      $('.program-section[data-section="overview"]').addClass('active');

    },
    statSlider: function(){

      // get width of window & no. of stats
      // if all stats visible then don't render arrows
      // else render both back and forward
      var statwidth = $('.stat:first').width();
      var windowwidth = $(window).width();

      if(windowwidth >= 1150) {
        var padleft = (windowwidth / 2) - 575;
        var visiblestats = (windowwidth - padleft) / statwidth;
      } else {
        var visiblestats = windowwidth / statwidth;
      }

      var statcount = $('.stat').length;

      // show arrows if necessary
      if(visiblestats < statcount){
        $('.stat-slider').addClass('has-arrows');
        $('.stat').addClass('showing');
      }


      // listen for arrow click
      // if forward, check if (.stat.showing).length > 1
        // yes, remove .showing from latest having
      // if back, check if first instance is has .showing
       // no, add .showing to latest lacking
      $('.stat-next').on('click', function(){
        var showingstats = $('.stat.showing').length;
        if(showingstats > 1){
          $('.stat').addClass('transition-out');
          setTimeout(function(){
            $('.stat.showing:first').removeClass('showing');  
            $('.stat').removeClass('transition-out');
          },200);

          $('.stat-prev').removeClass('disabled');
        } else {
          $('.stat-next').addClass('disabled');
        }
      });

      $('.stat-prev').on('click', function(){
        var showingstats = $('.stat.showing').length;
        if(showingstats < statcount){
          $('.stat').addClass('transition-out');
          setTimeout(function(){
            $('.stat:not(.showing):last').addClass('showing');
            $('.stat').removeClass('transition-out');
          },200);

          $('.stat-next').removeClass('disabled');
        } else {
          $('.stat-prev').addClass('disabled');
        }
      });

    },
    testimonialSlider: function(){

      // get width of window & no. of stats
      // if all stats visible then don't render arrows
      // else render both back and forward
      var statwidth = $('.testimonial:first').width();
      var windowwidth = $(window).width();

      if(windowwidth >= 1150) {
        var padleft = (windowwidth / 2) - 575;
        var visiblestats = (windowwidth - padleft) / statwidth;
      } else {
        var visiblestats = windowwidth / statwidth;
      }

      var statcount = $('.testimonial').length;

      // show arrows if necessary
      if(visiblestats < statcount){
        $('.testimonial-slider').addClass('has-arrows');
        $('.testimonial').addClass('showing');
      }


      // listen for arrow click
      // if forward, check if (.testimonial.showing).length > 1
        // yes, remove .showing from latest having
      // if back, check if first instance is has .showing
       // no, add .showing to latest lacking
      $('.testimonial-next').on('click', function(){
        var showingstats = $('.testimonial.showing').length;
        if(showingstats > 1){
          $('.testimonial').addClass('transition-out');
          setTimeout(function(){
            $('.testimonial.showing:first').removeClass('showing');  
            $('.testimonial').removeClass('transition-out');
          },200);

          $('.testimonial-prev').removeClass('disabled');
        } else {
          $('.testimonial-next').addClass('disabled');
        }
      });

      $('.testimonial-prev').on('click', function(){
        var showingstats = $('.testimonial.showing').length;
        if(showingstats < statcount){
          $('.testimonial').addClass('transition-out');
          setTimeout(function(){
            $('.testimonial:not(.showing):last').addClass('showing');
            $('.testimonial').removeClass('transition-out');
          },200);

          $('.testimonial-next').removeClass('disabled');
        } else {
          $('.testimonial-prev').addClass('disabled');
        }

      });

    },
    expander: function(){

      $('.expand-trigger').on('click', function(){
        $(this).toggleClass('open');
      });

    },
    // deprecated? only reset admin pass within admin
    resetPassword: function(){

      $('form#reset-admin-pass').on('submit', function(e){
        e.preventDefault();
        var thisform = $(this);
        $.ajax({
          url: '/adminchangepassword',
          type: 'POST',
          data: thisform.serialize()
        });        
      });


      $('form#reset-part-pass').on('submit', function(e){
        e.preventDefault();
      });

    },
    switchProgramSection: function(){

      // listen for click on local-navigation
      // get data-show="section name"
      // add .active to .program-section[data-section="section name"]
        // remove .active from siblings

      $('a.local-navigation').on('click', function(e){
        e.preventDefault();
        var toshow = $(this).data('show');

        $(this).addClass('active').siblings().removeClass('active');

        // animation dance
        // fade all out
        $('.program-section').addClass('moving-out');
        // fade active in
        setTimeout(function(){
          $('.program-section[data-section="' +toshow+ '"]').addClass('moving-in').removeClass('moving-out');
        },200);
        setTimeout(function(){
          $('.program-section[data-section="' +toshow+ '"]').addClass('active').removeClass('moving-in moving-out').siblings().removeClass('moving-in moving-out active');
        },400);
      });


      $('a.local-navigation-inline[data-show="participants"]').on('click', function(e){
        e.preventDefault();
        $('a.local-navigation[data-show="participants"]').addClass('active').siblings().removeClass('active');
        $('.program-section').addClass('moving-out');

        setTimeout(function(){
          $('.program-section[data-section="participants"]').addClass('moving-in').removeClass('moving-out');
        },200);
        setTimeout(function(){
          window.scrollTo(0,0);
          $('.program-section[data-section="participants"]').addClass('active').removeClass('moving-in moving-out').siblings().removeClass('moving-in moving-out active');
        },400);

      });

    },
    validateZip: function(){

      $('input#zip-check').keyup(function(){
        if(isNaN(userzip)){
          $(this).addClass('error');
        } else {
          if( userzip > 9999 && userzip < 99999 ){

            $(this).removeClass('error');
          } else {
            $(this).addClass('error');
          }
        }

      });

    },
    editMySections: function(){

      $('.core').on('click', '.detail-set-action', function(){
        var thissection = $(this).closest('.detail-section').data('section');
        var thisaction = $(this).data('action');
        $.post('/my-profile-' +thisaction+ '?section='+thissection,function(data){ });
      });


      $('.core').on('click', '.inline-action .clickable', function(){
        var thisfield = $(this).closest('.fieldy-set');
        var thisset = $(this).closest('.inline-action').data('set');
        var thisaction = $(this).data('action');

        var thiscount = 0;
        if(thisaction == 'add-another') {
          thiscount = $('.fieldy',thisfield).length;
        }
        $.post('/' +thisaction+ '?set='+thisset +'&count='+thiscount,function(data){ });
      });

    },
    triggers: function(){

    }

  };


  window.onload = function() {

    dyw.init();

  };
  window.onscroll = function() {
    dyw.headerBackground();
  };


})(window.jQuery);